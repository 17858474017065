<template>
	<div class="hk_eventWraper" v-if="selarticleList.length > 0">
		<div class="hk_flash_top">
			<div class="flex align_items_c justify_content_sb">
				<div class="hk_title">
					<img
						src="https://res.metaera.hk/resources/assets/images/hk/hk_flash_1.png"
						v-if="color != 'white'"
					/>
					<img
						v-else
						src="https://res.metaera.hk/resources/assets/images/hk/usa_kx.png"
					/>
				</div>
				<div
					class="flex align_items_c pointer hk_title_more hk_pointer"
					@click="$router.push({ path: '/news' })"
				>
					More
				</div>
			</div>
			<div class="hk_flash_bottom">
				<div
					class="hk_flash_title_bl1"
					:class="{ hk_flash_title_bl1_w: color == 'white' }"
				>
				</div>
				<div
					class="hk_flash_title_bl2"
					:class="{ hk_flash_title_bl2_w: color == 'white' }"
				>
				</div>
			</div>
		</div>
		<div class="hk_flash_list" :class="{ hk_flash_list_w: color == 'white' }">
			<div
				class="hk_flash_item"
				v-for="(item, index) in selarticleList"
				:key="index"
			>
				<div class="justify_content_sb align_items_c">
					<div class="hk_flash_item_top flex align_items_c btnitem fx">
						<div class="hk_flash_point"></div>
						<div
							class="hk_flash_time"
							:class="{ hk_flash_time_w: color == 'white' }"
							>{{ item.release_time }}</div
						>
					</div>
					<router-link
						target="_blank"
						:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
					>
						<div
							class="hk_flash_title pointer hovercolor"
							:class="{ hk_flash_title_w: color == 'white' }"
						>
							{{
								localLanguage == 2
									? item.title
										? item.title
										: item.title
									: item.title
							}}
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import clipboard from "clipboard";
import { newBus } from "../../components/pc/topHeader2.vue";
import { color } from "echarts";
export default {
	props: ["selarticleList", "color"],
	data() {
		return {
			currentShow: 0,
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		changePart(ind) {
			this.currentShow = ind;
		},
	},
};
</script>

<!-- hk_flash_list -->
<style scoped>
.hk_flash_list {
	margin-top: 20px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 0px;
	padding-bottom: 20px;
	border-radius: 10px;
	opacity: 1;
	background: #121212;
	box-sizing: border-box;
	border: 4px solid #0e0e0e;
	box-shadow: inset 0px 0px 23px 0px rgba(0, 0, 0, 0.6);
}

.hk_flash_list_w {
	background: white;
	box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0);
	border: 4px solid #ffffff;
}

.hk_flash_item_top {
	margin-top: 20px;
}

.hk_flash_point {
	width: 5px;
	height: 5px;
	border-radius: 5px;
	margin-right: 10px;
	opacity: 1;
	background: linear-gradient(
		40deg,
		#9945ff -8%,
		#8752f3 24%,
		#5497d5 53%,
		#43b4ca 68%,
		#28e0b9 85%,
		#19fb9b 122%
	);
}

.hk_flash_time {
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 字段三级 */
	color: #b2b2b2;
}

.hk_flash_time_w {
	color: #767676;
}

.hk_flash_title {
	margin-top: 10px;
	font-size: clamp(14px, calc(100vw * 16 / 1440), 18px);
	font-weight: bold;
	line-height: 28px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.hk_flash_title_w {
	color: #000000;
}

@media screen and (max-width: 900px) {
	.hk_flash_title {
		font-size: clamp(14px, calc(100vw * 14 / 414), 18px);
	}
}
</style>
